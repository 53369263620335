<template>
  <b-row>
    <v-dialog name="dialog-reveal"/>
    <b-col sm="12">
        <div class="iq-card-body profile-page p-0">
          <div class="profile-header">
            <div class="cover-container">
              <img style="opacity:17" src="../../../assets/images/page-img/profile-bg10.jpg" alt="profile-bg"
                   class="rounded img-fluid">
            </div>
            <div class="user-detail text-left mb-3 foto-odsazeni">
              <div class="profile-img">
                <img v-if="client.photos[0]" :src="$mediaBaseUrl + client.photos[0].url"
                     alt="profile-img"
                     class="avatar-130 img-fluid"/>
                <img v-else-if="client.gender == 1" alt=" profile-img" :src="maleImage" class="avatar-130 img-fluid"/>
                <img v-else-if="client.gender == 2" alt=" profile-img" :scr="femaleImage" class="avatar-130 img-fluid"/>
                <img v-else-if="client.gender == 3" alt=" profile-img" :scr="femaleImage" class="avatar-130 img-fluid"/>
              </div>

              <div class="profile-detail foto-odsazeni">
                <h3 class="">{{ client.first_name }} {{ client.last_name }}</h3>
              </div>

            </div>
            <div class="profile-info p-4 d-flex align-items-center justify-content-between position-relative">
              <div class="social-links">
                <a v-show="self" class="edit-profile" @click="$router.push({path: '/editcustomer'})">EDIT PROFILE</a>
                <ul class="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                  <li v-for="(item,index) in social" :key="index" class="text-center pr-3">
                    <a href="javasctipt:void(0)"><img :src="item" class="img-fluid rounded" alt="facebook"></a>
                  </li>
                </ul>
              </div>
              <div>
                <ul class="d-flex">
                  <li v-for="(i,index) in socialInfo" :key="index">
                    <h6 id="hlavicka-nadpis">{{ i.name }}</h6>
                    <p id="hlavicka-text">{{ i.value }}</p>
                  </li>
                </ul>
              </div>
              <div>
                <a v-show="!self" class="tlacitko-sluzby tlacitko-sluzby-pozice" @click="callClient()">VideoChat</a>
              </div>
            </div>
          </div>
        </div>
        <div class="iq-card-body p-0">
          <div class="user-tabing">
            <tab-nav :pills="true" id="pills-tab"
                     class="d-flex">

              <tab-nav-items class="p-0" :active="true" id="odkaz-podtrzeni" href="#profile-activity"
                             ariaControls="pills-profile" role="tab" :ariaSelected="false"
                             :title='$t("client.profile_about")'/>
              <tab-nav-items class="p-0" :active="false" id="odkaz-podtrzeni" href="#profile-profile"
                             ariaControls="pills-contact" role="tab" :ariaSelected="false"
                             :title='$t("client.profile_photos")'/>

              <tab-nav-items class="p-0" :active="false" id="odkaz-podtrzeni" href="#profile-friends"
                             ariaControls="pills-contact" role="tab" :ariaSelected="false"
                             :title='$t("client.profile_reviews")'/>
            </tab-nav>
            <hr>
          </div>
        </div>
        <div class="client-popis">
          <p>{{ this.client.description}}</p>
        </div>
        </b-col>
        <b-col class="center" sm="12">
          <div class="tab-content">
            <tab-content-item :active="true" id="profile-activity" aria-labelled-by="pills-activity-tab">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="tab-content">
                        <tab-content-item :active="true" id="contact-info" aria-labelled-by="pills-contact-info" >
                          <h4 >{{ $t("client.profile_contact_info") }}</h4>
                          <div class="row">

                            <div class="col-3 odsazeni-dolu odsazeni-nahoru">
                              <h6 id="nadpis">{{ $t("client.profile_phone") }}</h6>
                            </div>
                            <div class="col-9 odsazeni-dolu">
                              <div v-if="client_number">{{ client_number }}</div>
                              <a v-else @click="showRevealNumberDialog()">{{ $t("client.profile_reveal_number") }}</a>

                            </div>
                            <div class="col-3 odsazeni-dolu">
                              <h6 id="nadpis">{{ $t("client.profile_address") }}</h6>
                            </div>
                            <div class="col-9 odsazeni-dolu">
                              <p class="mb-0">{{ this.clientAddress }}</p>
                            </div>
                            <div class="col-3">
                              <h6 id="nadpis">{{ $t("client.profile_name") }}</h6>
                            </div>
                            <div class="col-9 odsazeni-dolu">
                              <p class="mb-0">{{ this.client.first_name }} {{ this.client.last_name }}</p>
                            </div>
                            <div class="col-3 odsazeni-dolu">
                              <h6 id="nadpis">{{ $t("client.profile_age") }}</h6>
                            </div>
                            <div class="col-9 odsazeni-dolu">
                              <p class="mb-0">{{ this.clientAge }} {{ $t("profile.years") }}</p>
                            </div>
                            <div class="col-3 odsazeni-dolu">
                              <h6 id="nadpis">{{ $t("client.profile_gender") }}</h6>
                            </div>
                            <div class="col-9 odsazeni-dolu">
                              <p class="mb-0">{{ this.getGender(this.client.gender) }}</p>
                            </div>
                            <div class="col-3 odsazeni-dolu">
                              <h6 id="nadpis">{{ $t("client.profile_natio") }}</h6>
                            </div>
                            <div class="col-9 odsazeni-dolu">
                              <p class="mb-0">{{ this.getNationality(this.client.nationality) }}</p>
                            </div>
                            <div class="col-3 odsazeni-dolu">
                              <h6 id="nadpis">{{ $t("client.profile_height") }}</h6>
                            </div>
                            <div class="col-9 odsazeni-dolu">
                              <p class="mb-0">{{ this.client.height }} cm</p>
                            </div>
                            <div class="col-3 odsazeni-dolu">
                              <h6 id="nadpis">{{ $t("client.profile_weight") }}</h6>
                            </div>
                            <div class="col-9 odsazeni-dolu">
                              <p class="mb-0">{{ this.client.weight }} kg</p>
                            </div>
                          </div>
                          <h4 class="mt-3 ">{{ $t("client.profile_services") }}</h4>
                          <div class="row">
                            <div class="col-9 odsazeni-nahoru">
                              <p class="mb-0">{{ this.getServices(this.client.services) }}</p>
                            </div>
                          </div>
                        </tab-content-item>
                        <br>
                        <div>
                          <a v-show="!self" class="tlacitko-sluzby" @click="callClient()">
                            {{ $t("profile.order_services") }}</a>
                        </div>
                      </div>
                    </div>
              </div>
            </tab-content-item>
            <tab-content-item :active="false" id="profile-profile" aria-labelled-by="pills-profile-tab">
              <div v-show="premium">
                <div id="gallery">
                  <lingallery :iid.sync="currentId" :width="width" :height="height" :items="photos"/>
                </div>
              </div>
              <div  class="center" v-show="!premium">
                <h2 class="premium">{{ $t("profile.photos_only_for_premium_users") }}</h2>
                <div class="flex-tlacitka">
                  <h3>
                    <router-link class="tlacitko-prihlasit" :to="{name: 'social.loginpage'}">{{ $t("profile.sign_in") }}</router-link>
                  </h3>
                  <h3>
                    <router-link class="tlacitko-registrovat" :to="{name: 'social.signupuserpage'}">{{ $t("profile.registration") }}</router-link>
                  </h3>
                </div>
                

              </div>
            </tab-content-item>
            <tab-content-item :active="false" id="profile-friends" aria-labelled-by="pills-friend-tab">
              <div class="iq-card">
                <div class="iq-card-body">
                  <h2>{{ $t("profile.reviews") }}</h2>
                  <p>{{ $t("profile.no_reviews") }}</p>
                </div>
              </div>
            </tab-content-item>

          </div>
        </b-col>
  </b-row>
</template>
<script>
import {socialvue} from '../../../config/pluginInit'
import ProfileImage from './ProfileImage'
import FriendTab from './ProfileFriends/FriendTab'
import About from './ProfileFriends/About'
import AddSocialPost from '../../Apps/Social/Components/AddSocialPost'
import Post from '../../../Model/Post'
import {Posts} from '../../../FackApi/api/SocialPost'
import SocialPost from '../../Apps/Social/Components/SocialPost'
import api from "@/api/api"
import googleApi from "@/api/googleApi"
import Lingallery from 'lingallery';
import i18n from '@/i18n'
import { contains } from 'ramda'

export default {
  name: 'Profile',
  props: {
    self: Boolean
  },
  mounted() {
    socialvue.index()

    api.post("client/getGenericData", {}).then((response) => {
      this.genericData = response.data

      if (this.self) {
        this.premium = true;
        this.client = this.$store.getters["Setting/clientData"]
        this.clientAge = this.getAgeFromDate(this.client.birth_date)
        this.setProfileData();
        this.setPhotos(this.client);
        this.client_number = this.client.phone_number;
      } else {
        this.userId = this.$route.params.id
        this.customerData = this.$store.getters["Setting/customerData"]


        api
            .post("client/getDetail", {client_id: this.userId, token: this.$store.getters["Setting/token"]})
            .then((response) => {
              if (response.data.status) {
                this.client = response.data.client
                this.clientAge = this.client.age
                if (this.customerData) {
                  if (this.customerData.premium_expires_date) {
                    this.premium = true
                    this.setPhotos(this.client);
                  }
                }

              }
              this.setProfileData()
            })
      }
    })
  }
  ,
  components: {
    FriendTab,
    ProfileImage,
    AddSocialPost,
    SocialPost,
    About,
    Lingallery
  }
  ,
  data() {
    return {
      femaleImage: require('../../../assets/images/user/female.jpg'),
      maleImage: require('../../../assets/images/user/male.jpg'),
      post: new Post(),
      clientData: null,
      client: null,
      clientAge: null,
      customerData: null,
      currentId: null,
      userId: null,
      client_number: null,
      genericData: null,
      clientAddress: null,
      socialPosts: Posts,
      name: null,
      showNumber: false,
      premium: false,
      social: [],
      width: 600,
      height: 400,
      photos: [],
      socialInfo: [],
      revealDialogParams: {
        title: i18n.t("dialog.reveal_number_title"),
        text: i18n.t("dialog.reveal_number_text"),
        buttons: [
          {
            title: i18n.t("dialog.reveal_number_cancel"),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: i18n.t("dialog.reveal_number_confirm"),
            handler: () => {
              this.revealNumber()
            }
          }
        ]
      },
      revealDialogGuest: {
        title: i18n.t("dialog.reveal_number_guest_title"),
        text: i18n.t("dialog.reveal_number_guest_text"),
        buttons: [
          {
            title: i18n.t("dialog.reveal_number_cancel"),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: i18n.t("dialog.reveal_number_guest_button"),
            handler: () => {
              this.goToRegistration()
            }
          }
        ]
      },
      lowCreditsDialog: {
        title: i18n.t("profile.lack_of_credits"),
        text: i18n.t("profile.action_need_credits"),
        buttons: [
          {
            title: i18n.t("profile.cancel"),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: i18n.t("profile.buy_credits"),
            handler: () => {
              this.$router.push({path: '/rechargecredits'})
            }
          }
        ]
      },
      loginRequestDialog: {
        title: i18n.t("profile.not_signed_in"),
        text: i18n.t("profile.for_calling_must_be_logged"),
        buttons: [
          {
            title: i18n.t("profile.cancel"),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: i18n.t("profile.sign_in"),
            handler: () => {
              this.$router.push({path: '/loginpage'})
            }
          }
        ]
      },

    }
  },

  computed: {
    token() {
      return this.$store.getters["Setting/token"];
    },
  },
  methods: {
    callClient() {
      if (!this.token) {
        this.$modal.show('dialog', this.loginRequestDialog);
        return;
      }

      api
        .post('calls/callClient', {
          token: this.token,
          client_id: this.client.id
        })
        .then((response) => {
          if (response.data.status) {
            this.$router.push({path: `/videocallcus/${response.data.call.id}/${response.data.call.to}`});
          } else {
            this.$modal.show('dialog', this.lowCreditsDialog);
          }
        });
    },
    goToRegistration() {
      this.$router.push({path: '/registration-user'})
    },
    getAgeFromDate(dateString) {
      let birth = new Date(dateString);
      let now = new Date();
      let beforeBirth = ((() => {
        birth.setDate(now.getDate());
        birth.setMonth(now.getMonth());
        return birth.getTime()
      })() < birth.getTime()) ? 0 : 1;

      return now.getFullYear() - birth.getFullYear() - beforeBirth;
    },
    confirmRevealNumber() {
      this.$modal.show('dialog', this.revealDialogParams)

    },
    showRevealNumberDialog() {
      if (this.customerData) {
        this.confirmRevealNumber()
      } else {
        this.$modal.show('dialog', this.revealDialogGuest)
      }


    },
    revealNumber() {
      if (this.customerData) {
        api.post("client/revealNumber", {
          client_id: this.client.id,
          token: this.$store.getters["Setting/token"]
        }).then((response) => {
          if (response.data.status) {
            if (response.data.phone_number) {
              this.client_number = response.data.phone_number
              api.post("customer/getSelf", {
                token: this.token
              }).then((newResponse) => {
                this.$store.dispatch('Setting/setCustomerData', newResponse.data.customer)
              })
              this.$modal.hide('dialog')
            } else {
              this.revealDialogParams.title = i18n.t("dialog.reveal_number_no_credits_title")
              this.revealDialogParams.text = i18n.t("dialog.reveal_number_no_credits_text")
              this.revealDialogParams.buttons = [
                {
                  title: i18n.t("dialog.reveal_number_ok"),
                  handler: () => {
                    this.$modal.hide('dialog')
                  }
                }
              ]
            }
          }
        })
      } else {
        this.$modal.show('dialog', this.revealDialogGuest)
      }

    },
    setProfileData() {
      this.socialInfo = [
        {
          name: i18n.t("profile.age") ,
          value: this.clientAge
        },
        {
          name: i18n.t("profile.gender"),
          value: this.getGender(this.client.gender)
        },
        {
          name: i18n.t("profile.orientation"),
          value: this.getOrientations(this.client.orientations)
        }
      ]
      this.clientAddress = googleApi.get("json?latlng=" + this.client.location[0] + "," + this.client.location[1] + "&key=AIzaSyDAIBuTBt8bPTxn4AIs77CsgqyvpSsb7C4")
          .then((response) => {
            this.clientAddress = response.data.results[3].formatted_address
          })

    },
    getGender(genderId) {

      return this.genericData.genders.filter(x => x.id === genderId)[0].name;

    }
    ,
    getNationality(nationalityId) {

      return this.genericData.nationalities.filter(x => x.id === nationalityId)[0].name;

    }
    ,
    setPhotos(clientData) {
      clientData.photos.forEach(element => {
        this.photos.push({
          src: this.$mediaBaseUrl + element.url,
          thumbnail: this.$mediaBaseUrl + element.url,
          caption: "",
          id: element.id
        })
      });
    },
    getOrientations(orientationsIds) {
      let orientations = ""
      for (const id of orientationsIds) {
        let result = this.genericData.orientations.filter(x => x.id === id);
        orientations += result[0].name + ", "
      }

      return orientations.slice(0, -2)
    }
    ,

    getServices(servicesIds) {
      let services = ""
      for (const id of servicesIds) {
        let result = this.genericData.services.filter(x => x.id === id);
        services += result[0].name + ", "
      }

      return services.slice(0, -2)
    }
    ,

    addPost(post) {
      this.socialPosts.unshift(post)
    }
  }
}
</script>
<style>
[dir=ltr][mode=dark] .nav-pills.profile-feed-items li a.active {
  background-color: #ef476f;
}

[dir=ltr][mode=dark] .profile-img img {
  height: 130px !important;
}

.premium {
  width: 100%;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.text-center.pl-3 p {
  color: #ef476f !important;
  color: #ef476f !important;
  font-size: 22px;
}

#contact-info a {
  color: #ef476f;
  cursor: pointer;
  font-weight: 600;
}

.lingallery {
  margin: 0 auto;
}

.lingalleryContainer {
  padding-top: 100px;
  padding-bottom: 100px;
}

.edit-profile {
  color: #ef476f !important;
  cursor: pointer !important;
  font-weight: 600;
  font-size: 22px !important;

}

.vue-dialog {
  background-color: #182039;
  border-radius: 10px;
  border: 5px solid #ef476f;
}

.vue-dialog-content {
  padding: 30px;
}

.vue-dialog-buttons {
  border-top: 5px solid #ef476f;
}

.vue-dialog-button:not(:first-of-type) {
  border-left: 5px solid #ef476f;
}

.vue-dialog-button:hover {
  background: #ef476f;
}

.flex-tlacitka {
  display: flex;
}

.tlacitko-prihlasit {
  background-color: #ef476f;
  color: white;
  height: 45px;
  width: 175px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 9998;
  border-radius: 5px;
  text-align: center;
}

.tlacitko-registrovat {
  background-color: #ef476f;
  color: white;
  width: 175px;
  height: 45px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 10;  
  border-radius: 5px;
  
}

.tlacitko-sluzby {
  background-color: #ef476f;
  color: white;
  width: 175px;
  height: 45px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 10;  
  border-radius: 5px;
}

.tlacitko-sluzby-pozice {
  position: relative;
  top: -15px;
}

.center {
  padding-bottom: 10px;
}

.foto-odsazeni {
  margin-left: 35px;
}

#nadpis {
  color: gray;
  margin-right: 20px;

}

#hlavicka-nadpis {
  color: gray;
  margin-right: 80px;
}

#hlavicka-text {
  color: white;
}

.tab-content {
  padding-left: 20px;
}

#pills-tab {
  padding-left: 25px;
  margin-bottom: 20px;
}

#contact-info h4 {
  color: #ef476f;
  font-size: 1em;
}

[dir=ltr][mode=dark] .nav-pills .nav-link.active {
  background: none;
}

#odkaz-podtrzeni {
  border-radius: 0px;
}

#odkaz-podtrzeni.active {
  border-bottom: 2px solid #ef476f;
}

hr {
  position: relative;
  top: -20px;
  margin-top: 0;
  margin-bottom: 0px;
}

.odsazeni-dolu {
  margin-bottom: 5px;
}

.odsazeni-nahoru {
  margin-top: 10px;
}

.client-popis {
  color: gray;
  margin-left: 40px;
  margin-top: 5px;
}

@media(max-width:400px) {
.flex-tlacitka {
  display: flex;
  flex-direction: column;
}
}


@media(max-width:995px) {
#hlavicka-nadpis {
  color: gray;
  margin-right: 30px;
}
}
</style>
